<template>
  <div>
    <div class="total-project-hour-dashboard hr-total-user text-center hrEmpinfo mxHeight d-flex justify-content-center align-items-center">         
          <div class="">                                    
            <p>               
              <skeleton-loader-vue
                type="circle"
                :width="120"
                :height="120"
                animation="wave"
                class="mx-auto"
              />            
            </p>
            <p class="card-title"><text-shimmer class="mb-25"></text-shimmer></p>
            <p class="user-count">
              <text-shimmer class="mb-25"></text-shimmer>
            </p>            
          </div>
        </div>      
  </div>
</template>

<script>
import SkeletonLoaderVue from "skeleton-loader-vue"
import TextShimmer from "./TextShimmer.vue"

export default {
    name:'HruserShimmer',
    components:{
        SkeletonLoaderVue,
        TextShimmer
    },
}
</script>

<style>

</style>